.App {
  padding: 2rem;
  justify-content: center;
  align-items: center;

  --primary: rgb(49, 118, 246);
  --primary-darker: rgb(46, 102, 206);
  --inactive-border: #aaa;
  --background: #fff;
  --background-darker: #eee;
  --foreground: #333;

  --qr-code-light-foreground: #333;
  --qr-code-light-background: #fff;
  --qr-code-dark-foreground: #f5f5f5;
  --qr-code-dark-background: #1b1b2a;

  color: var(--foreground);
  background: var(--background);

  flex-direction: column-reverse;
}

@media (prefers-color-scheme: dark) {
  .App {
    --background: #1b1b2a;
    --background-darker: #25252f;
    --foreground: #f5f5f5;

    --inactive-border: #999;
  }
}

.Title {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.InputField {
  margin-bottom: 0.5rem;

  color: var(--foreground);
  background-color: var(--background-darker);

  min-width: 275px;

  padding: 0.5rem 1rem;
  text-align: center;

  border-radius: 2rem;
  border: none;
  font-weight: 500;

  transition: color 0.2s ease-out;
}

.InputField::placeholder {
  color: var(--inactive-border);
}

.SubmitButton {
  background-color: var(--primary);
  color: var(--background);
  justify-content: center;

  padding: 0.5rem 1rem;
  border-radius: 2rem;
  border: none;

  font-weight: 500;

  cursor: pointer;
  transition: background-color 0.2s ease-out;
}

.SubmitButton:hover,
.SubmitButton:focus {
  background-color: var(--primary-darker);
}

@media (min-width: 800px) {
  .App {
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    box-sizing: border-box;
  }
}
