.Form,
.FormWrapper {
  display: flex;
  flex-direction: column;
}

.FormWrapper {
  margin-top: 2rem;
}

@media (min-width: 800px) {
  .QRCodeWrapper {
    align-self: center;
  }

  .FormWrapper {
    justify-content: center;

    margin-right: 4rem;
    margin-top: 0;
  }
}
