* {
  margin: 0;
  padding: 0;
}

:root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";

  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  height: 100%;
}

body,
#root,
.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

button,
input {
  font-size: 1rem;
  font-family: inherit;
  background-color: transparent;
  outline: none;
}

button {
  display: flex;
  align-items: center;
}

.ButtonIcon {
  height: 1rem;
  width: 1rem;
  display: inline-block;
  vertical-align: middle;
}

.ScreenReaderOnly {
  position: absolute;
  top: -999999vh;
  left: -9999999vh;
  width: 1px;
  overflow: hidden;
}
